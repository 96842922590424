import router from '../router'
import home from '@/api/index.js'
import { fstEncrypt } from '@/utils/cryptojs'
import global from '@/config'
// 新闻详情页面的跳转
export function toDetails(item) {
  const type = item.contributionType
  if (type === 'EXTERNAL_LINK_TYPE') { // 跳外链
    if (item.contributionResourcesDTOS.length) {
      for (let i = 0; i < item.contributionResourcesDTOS.length; i++) {
        const items = item.contributionResourcesDTOS[i]
        if (items.resourcesType === 'EXTERNAL_LINK') {
          window.open(items.resourcesUrl)
          break
        }
      }
    } else {
      const data = {
        id: item.id,
        zuulUrl: global.baseUrl
      }
      home.getAppOperationGetContributionInfoById(data).then(res => {
        if (res.success) {
          for (let i = 0; i < res.data.contributionResourcesDtoList.length; i++) {
            const items = res.data.contributionResourcesDtoList[i]
            if (items.resourcesType === 'EXTERNAL_LINK') {
              window.open(items.resourcesUrl)
              break
            }
          }
        }
      })
    }

    // const resource = item.contributionResourcesDTOS
    // const resItem = resource[0]
    // window.open(resItem.resourcesUrl)
    return
  }

  // if (item.columnSign === 'baozhiyuedu') {
  if (item.columnName === '报纸阅读' || item.columnSign === 'baozhiyuedu') {
    const index = item.contributionResourcesDTOS.findIndex(res => {
      return res.resourcesName.indexOf('pdf') !== -1
    })
    if (index !== -1) {
      let tem = global.baseUrl + 'media' + item.contributionResourcesDTOS[index].resourcesUrl
      tem = tem.replace('download', 'play')
      window.open(tem)
      return
    }
  }
  const encrypt = fstEncrypt(
    JSON.stringify(item.id),
    global.$aesKey,
    global.$aesIv
  )
  const { href } = router.resolve({
    path: '/newsDetail?id=' + encrypt
  })
  window.open(href)
}

// 广告的跳转
// export function adverTopage(item) {
//   if (item && item.advertisementIink) {
//     window.open(item.advertisementIink)
//   }
// }

export function newNavToPage(val) {
  if (!val.advertisementIink && val.dateUrl) {
    const { href } = router.resolve({
      path: val.dateUrl
    })
    window.open(href)
  } else {
    adverTopage(val)
  }
}

export function adverTopage(item) {
  if (item && item.advertisementIink) {
    window.open(item.advertisementIink)
  }
}

// 专题跳转
export function recommentJump(item, path) {
  console.log(item, path)
  if (item.externalLink) {
    window.open(item.externalLink)
    return
  }
  if (typeString(item.layoutMode)) {
    const info = JSON.parse(item.layoutMode)
    router.push({
      path: '/xuanzhouWenMing',
      query: {
        id: item.id,
        swiper: info.swiperInput,
        advise: info.adviseInput,
        name: item.topicName
      }
    })
    return false
  }
  // 字符串 'noneImage' 'hanveImage'是老数据
  if (item.layoutMode && (item.layoutMode === 'specialOne' || item.layoutMode === 'specialTwo' || item.layoutMode === 'specialThree')) {
    console.log('/' + item.layoutMode)
    router.push({
      path: '/' + item.layoutMode,
      query: {
        id: item.id
      }
    })
    // const routeData = router.resolve({
    //   query: {
    //     id: item.id
    //   },
    //   path: '/' + item.layoutMode
    // })
    // window.open(routeData.href, '_blank')
    return
  }
  router.push({
    path: path,
    query: {
      id: item.id,
      name: item.topicName
    }
  })
}

// 判断的是否是JSON字符串
function typeString(str) {
  if (typeof str === 'string') {
    try {
      const obj = JSON.parse(str)
      // 等于这个条件说明就是JSON字符串 会返回true
      if (typeof obj === 'object' && obj) {
        return true
      } else {
        // 不是就返回false
        return false
      }
    } catch (e) {
      return false
    }
  } return false
}

// 公共跳转的方法
export function jumpTopage(item) {
  if (item.otherLink) {
    window.open(item.otherLink)
  } else if (item.dataLink) {
    let colum = ''
    if (item.columnSign) {
      if (item.dataLink.indexOf('?') === -1) {
        colum = '?column=' + item.columnSign
      } else {
        colum = '&column=' + item.columnSign
      }
    }

    if (router.fullPath !== item.dataLink) {
      const { href } = router.resolve({
        path: item.dataLink + colum
      })
      window.open(href)
    }
  }
}

export default {
  toDetails,
  adverTopage,
  jumpTopage,
  recommentJump,
  newNavToPage
}
