// 简介 这里是单个视频样式

export default {
  props: {
    items: {
      require: false,
      type: Object
    },
    position: {
      type: String,
      default: 'center'
    },
    videoImg: {
      type: Boolean,
      default: true
    },
    opactiy: {
      type: Boolean,
      default: false
    }
  }
}
