<template>
  <div class="home" :class="{'huibaiSty':huibaiyemianData}">
    <!-- {{erweima}} -->
    <div class="erBoxdatas">
      <img
        class="erweiBox"
        @click="adverToPage(item)"
        v-for="(item,index) in erweima"
        :key="index"
        :src="item.advertisementImage | uploadFilters"
        alt
      />
      <div @click="toTopClick(50)" id="toTop" v-show="toTopBut">
        <img class="toTopbox" src="../assets/toTop.png" alt />
      </div>
    </div>
    <headWeb
      sign="WZDH"
      redsign="WZPH"
      @indexWidth="bottomMin=true"
      :showNavigation="false"
      bannersign="WZLOGO"
      :adver="true"
    />

    <div :class="{backheadcon:backheadcon&&!phTrue}" class="headWeb">
      <!-- <div class="backbox" v-if="phTrue">
        <img :src="phBac | uploadFilters" alt />
      </div>
      <div style="height:280px" v-if="phTrue"></div>
      <div class="logoDiv" :class="{logoBac:phTrue}">
        <img
          class="logo"
          v-if="logoData[0]"
          :src="logoData[0].advertisementImage | uploadFilters"
          alt
        />
        <img
          class="gif"
          v-if="logoData[1]"
          :src="logoData[1].advertisementImage | uploadFilters"
          alt
        />
      </div>-->
      <!-- 广告区域 -->

      <div class="moreAdvider" v-if="adverData&&adverData.length">
        <img
          class="myname"
          @click="adverToPage(item)"
          v-for="(item,index) in adverData"
          :key="index"
          :src="item.advertisementImage | uploadFilters"
          alt
        />
      </div>
      <div class="moreAdviderTwo" v-if="adverDataTwo&&adverDataTwo.length>1">
        <img
          class="mynametwo"
          @click="adverToPage(item)"
          v-for="(item,index) in adverDataTwo.slice(0,2)"
          :key="index"
          :src="item.advertisementImage | uploadFilters"
          alt
        />

      </div>

      <!-- 头条新闻 -->
      <!-- <headLine v-if="headData.length" :headData="headData" /> -->
      <div class="swiperOne" style="margin-top:11px" v-if="advertisementArrOne&&advertisementArrOne.length">
        <homeSwiper :swiperList="advertisementArrOne" style="width:100%;height:100%"></homeSwiper>
      </div>
      <div class="midContent">
        <!-- 中部左侧导航 -->
        <div class="leftMenu">
          <div
            class="menuOne"
            v-for="(item,index) in menu.slice(0,6)"
            :key="index"
            @mouseover="toOtherPart(index)"
            @click="guangbo(item)"
            :style="{background:choseIndex==index?'#6DBCFE':'#1d71b7'}"
          >
            <img v-if="choseIndex==index" class="maskBac" src="../assets/jixiPhoto/zhezhao.png" alt />
            <img :src="item.navigationCover | uploadFilters" alt />
            <span>{{item.navigationName}}</span>
          </div>
        </div>
        <!-- 中部左侧内容 绩溪资讯-->
        <!-- v-loading="JXZX"
          element-loading-text="拼命加载中"
          element-loading-spinner="el-icon-loading" -->
        <div
          class="rightContent"
          v-show="choseIndex==0"

        >
          <div>
            <!-- 头条 -->
            <headTitle v-if="headData.length" :headData="headData" />
            <rollingNotification v-if="choseIndex==0" :newsDta="midAllCon.JXZX[2]" />
            <div class="tuixin">
              <!-- 推荐轮播 -->
              <recommendBox style="width:568px;" titleType="shadeBo" />
              <!-- 右侧新闻列表 -->
              <div class="right">
                <div class="newsListcon">
                  <bigTitleTwo :mytitles="midAllCon.JXZX.slice(0,3)">
                    <div v-for="(item,index) in midAllCon.JXZX" :slot="[item.id]" :key="index">
                      <indexNewsList
                        v-for="(sonit,sonin) in item.datas"
                        :items="sonit"
                        :key="sonin"
                        fontSize="16px"
                      />
                    </div>
                  </bigTitleTwo>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- 媒体绩溪 -->
                <!-- v-loading="MTJX"
          element-loading-text="拼命加载中"
          element-loading-spinner="el-icon-loading" -->
        <div
        v-loading="MTJX"
          element-loading-text="拼命加载中"
          element-loading-spinner="el-icon-loading"
          class="rightContent"
          v-if="choseIndex==1"
        >
          <div v-if="!MTJX" style="width:100%">
            <!-- 外媒看绩溪 -->
            <bigTitle :mytitles="[midAllCon.MTJX[0]]" />
            <div class="newsTop" v-if="wmData.length" @click="handleClick(wmData[0])">
              <img
                v-if="wmData[0].cover&&JSON.parse(wmData[0].cover).length"
                :src="(JSON.parse(wmData[0].cover)[0]||'') | uploadFilters"
                alt
              />
              <img v-else :src="require(`@/assets/defaultImg/${configWeb.appName}.png`)" alt />
              <div class="content">
                <p class="title oneline">{{wmData[0].mainTitle}}</p>
                <div class="bottomT">
                  <div v-for="(i,j) in wmData[0].contributionResourcesDTOS" :key="j">
                    <div v-if="i.resourcesType === 'TEXT_IMAGE'" class="bottom">
                      {{ i.content|conChan }}
                      <span class="clickMore">[详情]</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="news">
              <div v-if="midAllCon.MTJX.length" class="newsDiv">
                <indexNewsList
                  v-for="(sonit,sonin) in  (midAllCon.MTJX[0].datas||'').slice(1,5)"
                  :items="sonit"
                  :key="sonin"
                  fontSize="16px"
                />
              </div>
              <div v-if="midAllCon.MTJX.length" class="newsDiv">
                <indexNewsList
                  v-for="(sonit,sonin) in  (midAllCon.MTJX[0].datas||'').slice(5,9)"
                  :items="sonit"
                  :key="sonin"
                  fontSize="16px"
                />
              </div>
            </div>
            <!-- 视频看绩溪 -->
            <bigTitle :mytitles="[midAllCon.MTJX[1]]" style="margin-top:36px" />
            <div v-if="midAllCon.MTJX.length" class="videoDiv">
              <videoItem
                position="left"
                class="video"
                v-for="(i,j) in (midAllCon.MTJX[1].datas||'').slice(0,5)"
                :key="j"
                :items="i"
              />
            </div>
          </div>
        </div>
        <div
          class="rightContent"
          v-if="choseIndex==2"
          v-loading="MLJX"
          element-loading-text="拼命加载中"
          element-loading-spinner="el-icon-loading"
        >
          <div class="jixiAll" v-if="!MLJX">
            <!-- 绩溪概况 -->
            <div class="jxName">
              <img
                v-if="midAllCon.MLJX[0].navigationCover.length"
                :src="midAllCon.MLJX[0].navigationCover | uploadFilters"
                alt
              />
              <img v-else :src="require(`@/assets/defaultImg/${configWeb.appName}.png`)" alt />
              <div class="mainCon">
                <div class="bacImg" v-if="midAllCon.MLJX.length">
                  <img
                    v-if="midAllCon.MLJX[0].datas.length&&JSON.parse(midAllCon.MLJX[0].datas[0].cover).length"
                    :src="JSON.parse(midAllCon.MLJX[0].datas[0].cover)[0]| uploadFilters"
                    alt
                  />
                  <img v-else src="../assets/jixiPhoto/tupian.png" alt />
                </div>
                <div class="content">
                  <p
                    v-if="midAllCon.MLJX[0].length&&midAllCon.MLJX[0].datas.length"
                    class="bottomT"
                    v-html="midAllCon.MLJX[0].datas[0].contributionResourcesDTOS[0].content"
                  ></p>
                  <p
                    v-else
                    class="bottomT"
                  >绩溪县隶属安徽省宣城市，位于安徽省南部，徽州六县之一，徽州文化的发源地之一， 有“无徽不成镇，无绩不成街”的说法。西与黄山区、歙县、旌德县接壤，东与浙江省临安 市交界，南与歙县相连，北与宣城市宁国市、旌德县毗邻，介于北纬29°57′～30°20′、东经 118°20′～118°55′之间。东西长59.5千米，南北宽42千米，属亚热带季风性湿润气候。境内 为低山丘陵山区，西部为黄山支脉，东部为西天目山脉，主要山峰皆在千米以上。绩溪县是前 中共中央总书记、前国家主席胡锦涛的祖籍地。</p>
                  <div class="roundDiv">
                    <div
                      v-for="(i,j) in midAllCon.MLJX.slice(2,8)"
                      :key="j"
                      :class="`position${j}`"
                      @click="guangbo(i)"
                    >
                      <img
                        :src="i.navigationCover|uploadFilters"
                        :class="{'rotate360':showIndex==j}"
                        @mouseenter="enterRotate(j)"
                        alt
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- 图说绩溪 -->
            <div class="jixiPic">
              <img
                v-if="midAllCon.MLJX[1].navigationCover.length"
                :src="midAllCon.MLJX[1].navigationCover| uploadFilters"
                alt
              />
              <img v-else :src="require(`@/assets/defaultImg/${configWeb.appName}.png`)" alt />
              <vueSeamless
                :class-option="defaultOption"
                :data="midAllCon.MLJX[1].datas"
                v-if="midAllCon.MLJX.length"
                style="width:100%;overflow:hidden"
              >
                <div class="scrollDiv" :style="{width:midAllCon.MLJX.length*433 + 'px'}">
                  <div
                    class="imgDiv"
                    v-for="(item,index) in (midAllCon.MLJX[1].datas||'')"
                    :key="index"
                    @click="handleClick(item)"
                  >
                    <img
                      v-if="item.cover&&JSON.parse(item.cover).length"
                      :src="JSON.parse(item.cover)[0]|uploadFilters"
                      alt
                    />

                    <img v-else :src="require(`@/assets/defaultImg/${configWeb.appName}.png`)" alt />
                  </div>
                </div>
              </vueSeamless>
            </div>
          </div>
        </div>
        <div
          class="rightContent"
          v-if="choseIndex==3"
          v-loading="SPZQ"
          element-loading-text="拼命加载中"
          element-loading-spinner="el-icon-loading"
        >
          <div class="jx_shipin" v-if="!SPZQ">
            <!-- 广播直播 -->
            <bigTitle :mytitles="[midAllCon.SPZQ[0]]" style="margin-top:14px" />
            <div class="video">
              <a :href="midAllCon.SPZQ[0].otherLink" target="_blank">
                <img :src="midAllCon.SPZQ[0].navigationCover | uploadFilters" alt />
              </a>
            </div>
            <!-- 现场直播 -->
            <div class="broadcast">
              <img :src="midAllCon.SPZQ[1].navigationCover | uploadFilters" alt @click="guangbo(midAllCon.SPZQ[1])"/>
              <div class="liveNes">
                <indexNewsList
                  v-for="(sonit,sonin) in  (midAllCon.SPZQ[1].datas||'').slice(0,2)"
                  :items="sonit"
                  :key="sonin"
                  fontSize="16px"
                />
              </div>
            </div>
          </div>
          <div class="jx_newsbg" v-if="!SPZQ">
            <bigTitle :mytitles="[midAllCon.SPZQ[2]]" style="margin-top:16px" />
            <div v-if="midAllCon.MTJX.length" class="videoDivNews">
              <videoItem
                class="videoNews"
                v-for="(i,j) in (midAllCon.SPZQ[2].datas||'').slice(0,2)"
                :key="j"
                :items="i"
              />
            </div>
            <div class="jx_zhuanlan">
              <bigTitle :mytitles="[midAllCon.SPZQ[3]]" />
              <div class="jx_zl">
                <div class="oneNews" v-for="(i,j) in midAllCon.WZJXZL" :key="j" @click="guangbo(i)">
                  <img :src="i.navigationCover | uploadFilters" alt v-if="i.navigationCover.length" />
                  <img v-else :src="require(`@/assets/defaultImg/${configWeb.appName}.png`)" alt />
                  <span class="oneline">{{i.navigationName}}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          class="rightContent"
          v-if="choseIndex==4"
          v-loading="WMJX"
          element-loading-text="拼命加载中"
          element-loading-spinner="el-icon-loading"
        >
          <div class="newsDivBig" v-if="!WMJX">
            <div class="jx_rd" v-for="(item,index) in wmNav" :key="index">
              <bigTitle :mytitles="[item]" style="margin-top:13px" :more="true" />
              <wenmingNewsList
                v-for="(sonit,sonin) in  (item.datas||'')"
                :items="sonit"
                :key="sonin"
                fontSize="16px"
              />
            </div>
          </div>
        </div>
        <div class="rightContent" v-show="choseIndex==5">
          <div class="liveDiv" >
            <bigTitle :mytitles="[menu[5]]" />
            <div id="dplayer"  style="width:848px;height: 477px;margin-top:10px"></div>
          </div>
        </div>
      </div>
      <!-- 专题 -->
      <div class="special">
        <bigTitle :mytitles="[menu[6]]" :more="true" lineType="noIcon" />
        <multipleSlider />
      </div>
      <!-- 第二个轮播 -->
      <div class="swiperOne" style="margin-top:22px">
        <homeSwiper :swiperList="advertisementArrTwo" style="width:100%;height:auto"></homeSwiper>
      </div>
      <!-- 看绩溪 游绩溪 -->
      <div
        class="bottomDiv"
        style="margin-top:22px"
        v-loading="MTJX"
        element-loading-text="拼命加载中"
        element-loading-spinner="el-icon-loading"
      >
        <div class="lookJX" v-if="!MTJX">
          <bigTitle :mytitles="[menu[7]]" :more="true" lineType="noIcon" />
          <div class="headTitle" v-if="midAllCon.MTJX.length">{{midAllCon.MTJX[1].navigationName}}</div>
          <div v-if="midAllCon.MTJX.length" class="videoDiv">
            <videoItem
              position="left"
              class="video"
              v-for="(i,j) in (midAllCon.MTJX[1].datas||'').slice(0,3)"
              :key="j"
              :items="i"
            />
          </div>
          <div class="headTitle" v-if="midAllCon.MTJX.length">{{midAllCon.MTJX[0].navigationName}}</div>
          <div v-if="midAllCon.MTJX.length">
            <indexNewsList
              v-for="(sonit,sonin) in  (midAllCon.MTJX[0].datas||'').slice(0,3)"
              :items="sonit"
              :key="sonin"
              fontSize="16px"
            />
          </div>
        </div>
        <div class="playJX" v-if="!MTJX">
          <bigTitle :mytitles="[menu[8]]" :more="true" lineType="noIcon" />
          <div class="maskImgDiv">
            <vue-hover-mask
              @click="handleClick(i)"
              v-for="(i,j) in YJX"
              :key="j"
              style="margin-top: 18px;"
            >
              <!-- 默认插槽 -->
              <img
                class="maskImg"
                v-if="i.cover&&JSON.parse(i.cover).length"
                :src="JSON.parse(i.cover)[0]|uploadFilters"
                alt
                srcset
              />
              <img
                v-else
                class="maskImg"
                :src="require(`@/assets/defaultImg/${configWeb.appName}.png`)"
                alt
              />
              <!-- title插槽 -->
              <template v-slot:action>
                <span class="midName">{{i.mainTitle}}</span>
              </template>
            </vue-hover-mask>
          </div>
        </div>
      </div>
      <div class="bottomSelect">
        <selectCom :selectData="midAllCon.XWWZ" />
        <selectCom :selectData="midAllCon.SZWZ" />
        <selectCom :selectData="midAllCon.XSQWZ" />
        <selectCom :selectData="midAllCon.QSYWZ" />
        <selectCom :selectData="midAllCon.QSYWZ2" />
      </div>
      <!-- <headLineNews /> -->
      <!-- <div class="gap"></div> -->
    </div>
    <footWeb :class="{minW:bottomMin}" />
    <!-- 飘窗 -->
    <bayWindow></bayWindow>
  </div>
</template>

<script>
// import headLine from '@/components/headLine/headLine'//头条
// import headLineNews from '@/components/headLine/headLinenews'
import selectCom from '@/components/selectCom/selectCom'
import vueHoverMask from '@/components/maskImg/maskImg'// 遮罩图片
import recommendBox from '@/components/recommend/recommend'
import bigTitle from '@/components/indexTitles/bigTitle'
import bigTitleTwo from '@/components/indexTitles/bigTitleTwo'
import homeSwiper from '@/components/homeSwiper/homeSwiper'// 广告轮播
// import bigTitleSimple from '@/components/indexTitles/bigTitleSimple'
import indexNewsList from '@/components/indexNewsList/indexNewsList'
import wenmingNewsList from '@/components/wenmingNewsList/indexNewsList'

import multipleSlider from '@/components/multipleSlider/multipleSlider'// 专题
// import cardSwiper from '@/components/cardSwiper/cardSwiper'
import videoItem from '@/components/videoItem/videoItemTwo'
// import noRuleImg from '@/components/noRuleImg/noRuleImg'
import footWeb from '@/components/footWeb/footWeb'
import headWeb from '@/components/headWeb/headWeb'// 导航
import headTitle from '@/components/headTitle/headTitle'// 头条
import rollingNotification from '@/components/rollingNotification/rollingNotification'
// import smallHeadline from '@/components/newsHeadHome/smallHeadline'
import home from '@/api/index.js'
import vueSeamless from 'vue-seamless-scroll'
import { adverTopage, jumpTopage, recommentJump, toDetails } from '@/utils/toDetail'
import bayWindow from './bayWindow/bayWindow'
import axios from 'axios'
import DPlayer from 'dplayer'
import Hls from 'hls.js'
export default {
  name: 'HomeView',
  components: {
    vueSeamless,
    headTitle,
    rollingNotification,
    homeSwiper,
    // leftMenu,
    // headLineNews,
    recommendBox,
    bigTitle,
    bigTitleTwo,
    // bigTitleSimple,
    indexNewsList,
    wenmingNewsList,
    multipleSlider,
    // cardSwiper,
    videoItem,
    vueHoverMask,
    // noRuleImg,
    footWeb,
    headWeb,
    bayWindow,
    selectCom
    // smallHeadline
  },

  data() {
    return {
      defaultOption: {
        step: 0.2, // 数值越大速度滚动越快
        // limitMoveNum: 10, // 开始无缝滚动的数据量 this.dataList.length
        // hoverStop: true, // 是否开启鼠标悬停stop
        direction: 2 // 0向下 1向上 2向左 3向右
        // openWatch: true, // 开启数据实时监控刷新dom
        // singleHeight: 0, // 单步运动停止的高度(默认值0是无缝不停止的滚动) direction => 0/1
        // singleWidth: 0, // 单步运动停止的宽度(默认值0是无缝不停止的滚动) direction => 2/3
        // waitTime: 1000 // 单步运动停止的时间(默认值1000ms)

      },
      loading: false,
      huibaiyemianData: false,
      zhuantiAdvs: [], // 专题广告
      adverData: [], // 广告数据
      headData: [], // 头条数据
      logoData: [], // 头部logo
      advertisementArrOne: [], // 广告轮播1
      advertisementArrTwo: [], // 广告轮播2
      showAnimate: false,
      JXZX: true, // 绩溪咨询
      MLJX: true, // 魅力绩溪
      MTJX: true, // 媒体绩溪
      SPZQ: true, // 视频直播
      WMJX: false, // 文明绩溪
      midAllCon: {
        DSZB: [], // 电视直播
        JXZX: [], // 绩溪咨询
        MLJX: [], // 魅力绩溪
        MTJX: [], // 媒体绩溪
        SPZQ: [], // 视频直播
        XWWZ: [], // 新闻网站
        SZWZ: [], // 市值网站
        XSQWZ: [], // 县市区网站
        QSYWZ: [], // 企事业网站
        QSYWZ2: [],
        WZJXZL: [] // 绩溪专栏
      },
      showIndex: 9,
      bottomMin: false,
      backheadcon: true,
      erweima: [],
      toTopBut: false,
      menu: [],
      choseIndex: 0,
      YJX: [],
      phTrue: false,
      phBac: '',
      wmData: [],
      adverDataTwo: [],
      wmNav: [] // 文明实践导航
    }
  },
  filters: {

    conChan(val) {
      let txt = document.createElement('div')
      txt.innerHTML = val
      const content = txt.innerText || txt.textContent
      txt = null
      return content.length > 100 ? content.slice(0, 100) + '...' : content
    }
  },
  computed: {
    configWeb() {
      return this.$store.state.$config
    }
  },
  created() {
    const that = this
    window.addEventListener('scroll', function (e) {
      const scrollTop =
        window.pageYOffset ||
        document.documentElement.scrollTop ||
        document.body.scrollTop
      if (scrollTop > 600) {
        that.toTopBut = true
      } else {
        that.toTopBut = false
      }
    })

    // this.adverDataLink('WZLOGO', 'logo')// logo
    // this.adverDataLink('WZPH', 'ph')// logo

    // this.Head(['TT'], 1, true, '', 'headData') // 这里是获取头条新闻
    // this.Head(['ZX'], 1, true, '', 'wmData')

    this.mycontentNav() // 获取网站首页导航

    this.adverDataLink('WZLGG') // 这里是获取列广告
    this.adverDataLink('WZLGGTwo', 'twoBanner')
    // this.mycontentNav('JXZX', 'oneData') // 获取网站首页小导航中的导航
    this.teshuZhuanti() // 获取专题
    this.huibaiYemian() // 页面灰白
    this.oneSwiper()// 广告轮播
    this.sideTipscon() // 二维码下载
    this.getWenMing()// 获取文明实践导航
  },
  mounted() {

  },
  methods: {
    videoPlay(val) {
      this.dp = new DPlayer({
        // 播放器的一些参数
        container: document.getElementById('dplayer'),
        video: {
          url: val.columnLink, // 视频地址
          type: 'customHls',
          pic: val.navigationCover.length ? val.navigationCover : '',
          customType: {
            customHls: function (video, player) {
              const hls = new Hls()// 实例化Hls  用于解析m3u8
              hls.loadSource(video.src)
              hls.attachMedia(video)
            }
          }
        },
        autoplay: false, // 是否自动播放
        theme: '#FADFA3', // 主题色
        loop: true, // 视频是否循环播放
        lang: 'zh-cn',
        screenshot: false, // 是否开启截图
        hotkey: true, // 是否开启热键
        preload: 'auto', // 视频是否预加载
        volume: 0.7, // 默认音量
        mutex: true // 阻止多个播放器同时播放，当前播放器播放时暂停其他播放器
      })
      this.$nextTick(() => {
        document.querySelector('#dplayer').oncontextmenu = () => {
          document.querySelector('.dplayer-menu').style.display = 'none'
          document.querySelector('.dplayer-mask').style.display = 'none'
          return false
        }
      })
    },
    enterRotate(j) {
      this.showIndex = j
    },
    handleClick(item) {
      toDetails(item)
    },
    toOtherPart(i) { // 中部hover跳转
      this.choseIndex = i
      this.showIndex = 9
    },
    toTopClick(i) {
      let timer
      // 参数i表示间隔的幅度大小，以此来控制速度
      document.documentElement.scrollTop -= i
      if (document.documentElement.scrollTop > 0) {
        timer = setTimeout(() => this.toTopClick(i))
      } else {
        if (timer) {
          clearTimeout(timer)
        }
      }
    },
    getWMlist(columnId) {
      return new Promise((resolve, reject) => {
        axios.post('https://gateway2.fst1994.net/v2/api/app/ap_contribution/app/AppContribution/getColumnContribution?appName=掌上绩溪&page=1&rows=5&columnId=' + columnId)
          .then((res) => {
            if (res.data.success) {
              // console.log('haahahahah文明实践裂变', res.data.data.HD)
              resolve(res.data.data.pageList.items)
            }
          })
          .catch((err) => {
            console.log(err)
          })
      })
    },
    // /v2/api/app/ap_contribution/app/AppContribution/getAppNavigtion
    getWenMing() { // 获取导航的接口
      axios.post('https://gateway2.fst1994.net/v2/api/app/ap_contribution//navigation/select/app?typeId=60&regionId=1166')
        .then(async (res) => {
          if (res.data.success) {
            const sourceArr = res.data.data.filter(item => {
              return item.isShow === 0
            })
            this.wmNav = sourceArr.slice(1, 5)
            for (let i = 0; i < this.wmNav.length; i++) {
              this.wmNav[i].datas = await this.getWMlist(this.wmNav[i].columnId)
              this.wmNav[i].wenming = true
            }
            // console.log(this.wmNav)
          }
        })
        .catch((err) => {
          console.log(err)
        })
    },
    adverToPage(item) {
      adverTopage(item)
    },
    HeadAttr(attrList, columnSigns, name) {
      home
        .getContributionByColumnAttr({ page: 1, rows: 1, attrSignList: attrList, columnSigns: columnSigns }, { appName: this.configWeb.appName })
        .then((res) => {
          if (res.success) {
            this[name] = res.data.items
          }
        })
    },
    // 头条新闻
    navList(data, name, sta) {
      home
        .getAppOperationGetNavListBySign({
          sign: data,
          appName: this.configWeb.appName
        }).then((res) => {
          if (res.success) {
            if (sta) {
              if (data === 'JXZX') {
                this.HeadAttr(['ZX'], [res.data[0].info[0].columnSign], 'headData')
              }
              if (data === 'MTJX') {
                this.HeadAttr(['TT'], [res.data[0].info[0].columnSign], 'wmData')
              }
              this.$set(this.midAllCon, data, res.data[0].info)
              const arrTwo = ['XWWZ', 'SZWZ', 'XSQWZ', 'QSYWZ', 'QSYWZ2', 'WZJXZL', 'DSZB']

              if (!arrTwo.includes(data)) {
                for (let i = 0; i < res.data[0].info.length; i++) {
                  this.HeadlinesList([], 10, true, i.columnSign, this.midAllCon[data][i], true, data)
                }
              }
            } else {
              this[name] = res.data[0].info
              if (name === 'menu') {
                this.menu.map((item) => {
                  console.log('hh', item)
                  if (item.navigationSign === 'YJX') {
                    this.HeadlinesList([], 4, true, item.columnSign, 'YJX', false)
                  }
                  return item
                })
                this.$nextTick(() => {
                  this.videoPlay(this.menu[5])
                })
              }
            }
          }
        })
    },
    // 头条新闻
    HeadlinesList(arrList, number, flage, listTitle, name, sta, loadSign) {
      home
        .getAppOperationGetColumnContribution({
          appName: this.configWeb.appName,
          attrSignList: arrList,
          rows: number,
          isShowInfo: flage,
          columnSign: listTitle
        })
        .then((res) => {
          if (res.success) {
            // console.log('hahahaha', res)
            if (sta) {
              this.$set(name, 'datas', res.data.items)
              this[loadSign] = false
              // setTimeout(() => {
              //   this[loadSign] = false
              // }, 1000)
            } else {
              this[name] = res.data.items
              // console.log(res.data.items, '推===《', arrList)
            }
          }
        })
    },
    huibaiYemian() {
      this.adverDataLink('YMHB', 'yemian')
    },
    // 两个广告轮播
    oneSwiper() {
      this.adverDataLink('WZGGLB1', 'adver1')
      this.adverDataLink('WZGGLB2', 'adver2')
    },
    // 侧边栏二维码
    sideTipscon() {
      this.adverDataLink('CBEWM', 'erweima')
    },

    //
    // 广告的接口
    adverDataLink(sign, its) {
      home
        .getAppOperationGetAdvListBySign({
          sign,
          appName: this.configWeb.appName
        })
        .then((res) => {
          if (res.success && res.data && res.data.length) {
            if (its === 'erweima') {
              this.erweima = res.data
            } else if (its === 'yemian') {
              for (let i = 0; i < res.data.length; i++) {
                if (res.data[i].advertisementSign === 'WYHB') {
                  this.huibaiyemianData = true
                  break
                }
              }
            } else if (its === 'adver1') {
              this.advertisementArrOne = res.data
            } else if (its === 'adver2') {
              this.advertisementArrTwo = res.data
            } else if (its === 'logo') {
              this.logoData = res.data
            } else if (its === 'ph') {
              // console.log('ph===>', res.data[0].advertisementImage)
              this.phTrue = true
              this.phBac = res.data[0].advertisementImage
            } else if (its === 'twoBanner') {
              this.adverDataTwo = res.data
            } else if (its) {
              this.$set(its, 'datas', res.data)
            } else {
              this.adverData = res.data
            }
          }
        })
    },
    // 导航接口
    navData(data) {
      return new Promise((resolve, reject) => {
        home
          .getAppOperationGetNavListBySign({
            sign: data,
            appName: this.configWeb.appName
          }).then((res) => {
            if (data === 'JXZX') {
              this.HeadAttr(['ZX'], [res.data[0].info[0].columnSign], 'headData')
            }
            if (data === 'MTJX') {
              this.HeadAttr(['TT'], [res.data[0].info[0].columnSign], 'wmData')
            }
            resolve(res.data[0].info)
          })
      })
    },
    // 获取导航
    async mycontentNav() {
      // 导航
      this.navList('WZSYNR', 'menu', false)
      // this.menu = await this.navData('WZSYNR')
      // console.log('this.menu===>', this.menu)

      const signArr = ['JXZX', 'MTJX', 'MLJX', 'SPZQ', 'DSZB', 'XWWZ', 'SZWZ', 'XSQWZ', 'QSYWZ', 'QSYWZ2', 'WZJXZL']
      const arrTwo = ['XWWZ', 'SZWZ', 'XSQWZ', 'QSYWZ', 'QSYWZ2', 'WZJXZL', 'DSZB']
      this.menu.map((item) => {
        if (item.navigationSign === 'YJX') {
          this.HeadlinesList([], 4, true, item.columnSign, 'YJX', false)
          // console.log('this.YJX', this.YJX)
        }
        return item
      })

      // const signOb = {}
      for (let i = 0; i < signArr.length; i++) {
        // this.navList(signArr[i], '', true)

        const arr = await this.navData(signArr[i])
        this.$set(this.midAllCon, signArr[i], arr)
      }
      Object.keys(this.midAllCon).forEach((item) => {
        this.midAllCon[item].map((itemSon, indexSon) => {
          if (!arrTwo.includes(item)) {
            this.HeadlinesList([], 10, true, itemSon.columnSign, this.midAllCon[item][indexSon], true, item)
          }
          return itemSon
        })
      })
      console.log('suoyoshuju', this.midAllCon)
      // this.midAllCon.JXZX = this.midAllCon.JXZX.slice(0, 3)
    },

    // 便民服务点击跳转
    bianminClick(item) {
      adverTopage(item)
    },
    // 广播跳转
    guangbo(item) {
      jumpTopage(item)
    },
    // 推荐广告的点击事件
    tuijianClick(item) {
      recommentJump(item, '/newslist')
    },
    teshuZhuanti() {
      home
        .topicAttr({

        }, {
          attrSign: 'TJ',
          page: 1,
          pageSize: 2,
          appName: this.configWeb.appName

        })
        .then(async (res) => {
          if (res.success) {
            this.zhuantiAdvs = res.data.items
            // console.log('专题广告', res.data.items)
          }
        })
    }
  }
}
</script>

<style lang="scss" scoped>
.headWeb {
  position: relative;
  // width: 100%;
  .backboxzhe {
    width: 100%;
    height: 160px;
    background-color: transparent;
    cursor: pointer;
  }
  .backbox {
    width: 100%;
    position: absolute;
    top: 0px;
    z-index: -1;
    img {
      width: 100%;
    }
  }
}
.erBoxdatas {
  position: fixed;
  right: 0px;
  top: 300px;
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 999;
  .erweiBox {
    width: 73px;
    height: auto;
  }
  .toTopbox {
    width: 57px;
    height: 57px;
    margin-top: 20px;
    cursor: pointer;
  }
}
.huibaiSty {
  filter: grayscale(100%);
}
.home {
  position: relative;
  // background-color: #f1f6fa;
  .minW {
    min-width: calc($primaryWidth + 20px);
  }
}
// background-color: #f1f6fa;
.logoBac {
  // width: 1920px;
  margin: 0px auto;
}
.logoDiv {
  width: $primaryWidth;
  // margin: 0px auto;
  display: flex;
  // justify-content: space-between;
  align-items: center;
  height: 120px;
  padding: 0 36px;
  box-sizing: border-box;

  .logo {
    width: 400px;

    // height: 94px;
  }
  .gif {
    width: 648px;
    // height: 237px;
    margin-bottom: 20px;
    margin-left: 96px;
  }
}
.moreAdvider {
  background-color: #fff;
  width: $primaryWidth;
  margin: 0px auto;
  display: flex;
  flex-direction: column;
  .myname {
    cursor: pointer;
    width: 100%;
    height: auto;
    &:not(:first-child) {
      margin-top: 7px;
    }
  }
}
.midContent {
  background-color: #fff;
  width: $primaryWidth;
  margin: 11px auto 0 auto;
  display: flex;
  flex-direction: row;
  .leftMenu {
    display: flex;
    flex-direction: column;
    cursor: pointer;
    .menuOne {
      width: 178px;
      height: 89px;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 19px;
      font-weight: 500;
      color: #ffffff;
      border-top: solid 1px #fff;
      position: relative;
      .maskBac {
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        bottom: 0;
      }
      span {
        position: absolute;
        z-index: 2;
        left: 73px;
      }
      img {
        position: absolute;
        z-index: 2;
        left: 33px;
      }
    }
  }
  .rightContent {
    width: 957px;
    margin-left: 17px;
    display: flex;
    position: relative;
    // flex-direction: column;
    .liveDiv {
      display: flex;
      flex-direction: column;
    }
    .tuixin {
      width: 100%;
      height: 403px;
      margin: 0px auto;
      display: flex;
      // justify-content: space-between;
      // margin-top: 20px;
      .right {
        display: flex;
        flex-direction: column;
        height: 100%;
        width: 388px;
        overflow: hidden;

        .newsListcon {
          padding-left: 30px;
          box-sizing: border-box;
        }
      }
    }
    .newsTop {
      display: flex;
      flex-direction: row;
      align-items: center;
      cursor: pointer;
      margin-top: 25px;
      img {
        width: 160px;
        height: 100px;
      }
      .content {
        margin-left: 13px;
        .title {
          font-size: 16px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #333333;
          width: 497px;
        }
        .bottomT {
          width: 774px;
          height: 33px;
          font-size: 13px;
          font-family: Source Han Sans CN;
          font-weight: 400;
          color: #969696;
          line-height: 19px;
          margin-top: 15px;
          // display: flex;
          // flex-direction: row;

          .clickMore {
            color: $primaryColor;
            cursor: pointer;
          }
        }
      }
    }
    .news {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      margin-top: 19px;
      .newsDiv {
        width: 433px;
      }
    }
    .videoDiv {
      width: 100%;
      display: flex;
      justify-content: space-between;
      margin-top: 11px;
      .video {
        width: 179px;
        height: 115px;
      }
    }
    .jixiAll {
      display: flex;
      flex-direction: column;
      width: 100%;
      .jxName {
        width: 100%;
        height: 300px;
        background-image: url("../assets/jixiPhoto/fugukuang.png");
        background-size: 100% 100%;
        padding: 24px 0 0 13px;
        box-sizing: border-box;
        .mainCon {
          display: flex;
          flex-direction: row;
          // align-items: center;
          .bacImg {
            width: 336px;
            height: 230px;
            border: 1px solid #dcdcdc;
            box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.21);
            img {
              width: 336px;
              height: 230px;
            }
          }

          .content {
            margin-left: 13px;

            .bottomT {
              width: 546px;
              height: 116px;
              font-size: 13px;
              font-family: Microsoft YaHei;
              font-weight: 400;
              color: #333333;
              line-height: 20px;
              // margin-top: -19px;
              overflow: hidden;
              -webkit-line-clamp: 6;
              text-overflow: ellipsis;
              display: -webkit-box;
              -webkit-box-orient: vertical;

              // display: flex;
              // flex-direction: row;

              .clickMore {
                color: $primaryColor;
              }
            }
          }
        }
        .roundDiv {
          // width: 100%;
          width: 551px;
          height: 110px;
          display: flex;
          justify-content: center;
          background-size: 100% 100%;
          background-repeat: no-repeat;
          margin-top: 10px;
          // justify-content: space-around;
          background-image: url("../assets/jixiPhoto/bolang2.png");
          position: relative;
          img {
            width: 63px;
            height: 63px;
          }
          .position0 {
            position: absolute;
            top: 34px;
            left: 2px;
          }
          .position1 {
            position: absolute;
            top: 10px;
            left: 99px;
          }
          .position2 {
            position: absolute;
            top: 37px;
            left: 205px;
          }
          .position3 {
            position: absolute;
            top: 6px;
            left: 294px;
          }
          .position4 {
            position: absolute;
            top: 52px;
            left: 391px;
          }
          .position5 {
            position: absolute;
            top: 7px;
            left: 482px;
          }
          // .bolang {
          //   width: 454px;
          //   height: 67px;
          //   margin-top: 10px;
          // }
          @keyframes rotate360 {
            100% {
              transform: rotate(360deg);
            }
          }
          .rotate360 {
            animation: rotate360 0.5s ease-out 0s;
          }
        }
      }
    }

    .jx_shipin {
      width: 561px;
      height: 540px;
      // float: left;
      .video {
        width: 561px;
        height: 380px;
        float: left;
        margin-top: 14px;
      }
      .broadcast {
        width: 561px;
        height: 74px;
        float: left;
        cursor: pointer;
        // margin-top: 20px;
        img {
          width: 75px;
          height: 73px;
          float: left;
        }
        .liveNes {
          width: 410px;
          float: left;
          margin: 5px 0 0 13px;
        }
      }
    }
    .jx_newsbg {
      width: 354px;
      height: 540px;
      float: right;
      margin-left: 20px;
      // margin-top: 10px;
      .videoDivNews {
        width: 100%;
        display: flex;
        justify-content: space-between;
        margin-top: 11px;
        .videoNews {
          width: 170px;
          height: 115px;
        }
      }
      .jx_zhuanlan {
        width: 372px;
        height: 332px;
        float: left;
        margin-top: 40px;
        cursor: pointer;
        .jx_zl {
          width: 372px;
          height: 274px;
          display: flex;
          flex-wrap: wrap;
          justify-content: space-between;
          .oneNews {
            width: 170px;
            height: 49px;
            display: flex;
            align-items: center;
            margin-top: 5px;
            img {
              width: 49px;
              height: 49px;
              float: left;
            }
            span {
              width: 122px;
              padding-left: 6px;
              font-weight: bold;
              font-size: 14px;
              color: #393939;
            }
          }
        }
      }
    }
    .newsDivBig {
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      .jx_rd {
        width: 425px;
        height: 260px;
        float: right;
        margin-top: 13px;
      }
    }
  }
  .jixiPic {
    width: 100%;
    padding: 24px 0 0 13px;
    box-sizing: border-box;

    .scrollDiv {
      width: 2580px;
      height: 181px;
      display: flex;
      flex-direction: row;
      align-items: center;
      position: relative;
      .imgDiv {
        width: 258px;
        height: 170px;
        background: #ffffff;
        box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.21);
        display: flex;
        justify-content: center;
        align-items: center;
        margin-right: 13px;
        img {
          width: 244px;
          height: 156px;
        }
      }
    }
  }
  .videoLive {
    position: absolute;
    top: 62px;
  }
}
.special {
  width: $primaryWidth;
  margin: 22px auto 0 auto;
}
.swiperOne {
  width: $primaryWidth;
  height: 111px;
  display: flex;
  margin: 0 auto;
}
.bottomDiv {
  width: $primaryWidth;
  height: 364px;
  display: flex;
  flex-direction: row;
  margin: 22px auto 0 auto;
  justify-content: space-between;
  .lookJX {
    width: 629px;
    .videoDiv {
      width: 100%;
      display: flex;
      justify-content: space-between;
      margin-top: 11px;
      .video {
        width: 203px;
        height: 123px;
      }
    }
  }
  .headTitle {
    margin-top: 17px;
    width: 95px;
    font-size: 16px;
    text-align: center;
    border-left: 2px solid #1d71b7;
  }
  .playJX {
    width: 472px;
    .maskImgDiv {
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      .maskImg {
        width: 230px;
        height: 132px;
      }
      .midName {
        font-size: 19px;
      }
    }
  }
}
.bottomSelect {
  width: $primaryWidth;
  margin: 37px auto 0 auto;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-bottom: 40px;
}
.backheadcon {
  width: calc($primaryWidth + 50px);
  background-color: #fff;
  margin: 0px auto;
}

.mynewsCon {
  width: $primaryWidth;
  display: flex;
  justify-content: space-between;
  margin: 0px auto;
  height: 358px;
  overflow: hidden;
  .sonlistD {
    width: 31%;
  }
}
.imgboxcon {
  width: $primaryWidth;
  margin: 0px auto;
  margin-bottom: 10px;
  .imins {
    width: 100%;
    height: auto;
  }
}

.bianmin {
  width: $primaryWidth;
  margin: 0px auto;
  display: flex;
  flex-wrap: wrap;
  .bianmingItem {
    cursor: pointer;
    width: 220px;
    height: 45px;
    border: 1px solid #999;
    margin-bottom: 15px;
    margin-right: 25px;
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 14px;
    box-sizing: border-box;
    &:nth-child(5n + 5) {
      margin-right: 0px;
    }
    .bianmIcon {
      width: 25px;
      height: 25px;
      object-fit: contain;
      margin-right: 10px;
    }
  }
}
.youqing {
  width: $primaryWidth;
  margin: 0px auto;
  .mylinkdata {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    .linIm {
      cursor: pointer;
      margin-right: 14px;
      font-size: 14px;
      margin-bottom: 14px;
    }
  }
}
.moreAdviderTwo{
  display: flex;
  justify-content: space-between;
  width: 1200px;
  margin: 10px auto;
  .mynametwo{
    width: 49.5%;
    height: 80px;
    cursor: pointer;

  }
}
</style>
