<template>
  <div class="videoBox" @click="clickBtn">
    <img class="bof" src="../../assets/jixiPhoto/play.png" alt />
    <img
      class="thu"
      v-if="items.cover&&JSON.parse(items.cover).length"
      :src="JSON.parse(items.cover)[0]|uploadFilters"
      alt
    />
    <img class="thu" v-else :src="require(`@/assets/defaultImg/${appName}.png`)" alt />
    <div class="tuitit boxs oneline">{{items.mainTitle}}</div>
  </div>
</template>

<script>
import props from './props.js'
import { toDetails } from '@/utils/toDetail'
export default {
  data() {
    return {
      appName: this.$store.state.$config.appName
    }
  },
  mixins: [props],
  methods: {
    clickBtn() {
      toDetails(this.items)
    }
  }
}
</script>

<style  lang="scss" scoped>
.videoBox {
  cursor: pointer;
  width: 100%;
  height: 100%;
  position: relative;
  .thu {
    width: 100%;
    height: 100%;
    border-radius: 6px;
  }
  .bof {
    width: 20px;
    height: 20px;
    position: absolute;
    left: 5px;
    bottom: 7px;
  }
  .tuitit {
    position: absolute;
    bottom: 0px;
    left: 26px;
    width: calc(100% - 40px);
    height: 30px;
    color: #fff;

    line-height: 30px;
    font-size: 13px; // display: flex;
    // justify-content: center;
    text-align: center;
  }
}
</style>
