// 简介 这里是新闻首页常用的一些头部

export default {
  props: {
    lineType: {
      require: false,
      type: String,
      default: 'pig' // pig 标题有背景大模块标题   smallline 无背景标题 smallnoline 无背景标题
    },
    changeType: {
      require: false,
      type: String,
      default: 'hover' // hover 触摸变化   click 点击变化
    },
    more: {
      require: false,
      type: Boolean,
      default: false // 是否有更多按钮
    },
    mytitles: {
      require: true,
      default: () => {
        return []
      }
    },
    simpleTitle: {
      require: false,
      type: String,
      default: '' // hover 触摸变化   click 点击变化
    }
  }
}
