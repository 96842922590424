<template>
  <div class="headList" @click="todetailsList">
    <div class="sign">
      <div class="circles"></div>
    </div>
    <div class="titles oneline" :style="`font-size:${fontSize}`">{{items.mainContributionTitle}}</div>
    <div class="times">{{items.releaseTime|times}}</div>
  </div>
</template>

<script>
import props from './props.js'
// import { toDetails } from '../../utils/toDetail'
export default {
  mixins: [props],
  filters: {
    times(data) {
      if (data) {
        const one = data.split(' ')
        if (one.length) {
          const two = one[0].split('-')
          two.shift()
          return two.join('-')
        }
      }
    }
  },
  methods: {
    todetailsList() {
      if (this.items.contributionOtherUrl) {
        window.open(this.items.contributionOtherUrl, '_blank') // 新窗口打开外链接
      } else {
        const { href } = this.$router.resolve({
          path: '/newsDetail?id=' + this.items.id + '&from=wenming'
        })
        window.open(href)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.headList {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 38px;
  .circles {
    width: 4px;
    height: 4px;
    background-color: #cecdcd;
    border-radius: 10px;
    margin-right: 10px;
  }
  .titles {
    // font-size: 14px;
    color: #707070;
    width: calc(100% - 110px);
    cursor: pointer;
  }
  .times {
    margin-left: 12px;
    text-align: right;
    width: 80px;
    color: #b6b6b6;
    font-size: 14px;
  }
}
</style>
