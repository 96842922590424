<template>
  <div class="swiper-box">
    <div class="swiper-List" v-if="carouselMark" :style="{ height: height }">
      <el-carousel class="lunbobox" height="100%" :autoplay="true" trigger="click" arrow="never">
        <el-carousel-item v-for="(item, index) in comDs" :key="index + 'hh'">
          <div class="myitemsCon" @click="adverToPageXuan(item)">
            <img class="imagescon" v-if="item.advertisementImage" :src="item.advertisementImage | uploadFilters" alt />
            <img class="imagescon" v-else :src="require(`@/assets/defaultImg/${appName}.png`)" alt />
          </div>
        </el-carousel-item>
      </el-carousel>
    </div>
    <div class="swiper-vertical" v-else>
      <div class="vertical" @click="adverToPageXuan(item)" v-for="(item, index) in comDs" :key="index" :style="{ height: height }">
        <img class="verticalImg" :style="{ height: height }" v-if="item.advertisementImage" :src="item.advertisementImage | uploadFilters" alt=""
          srcset="">
        <img class="verticalImg" :style="{ height: height }" v-else :src="require(`@/assets/defaultImg/${appName}.png`)" alt />
      </div>
    </div>
  </div>
</template>

<script>
import home from '@/api/index.js'
import { adverTopage } from '@/utils/toDetail'
export default {
  props: {
    swiperSign: {
      type: String,
      default: ''
    },
    height: {
      type: String,
      default: '200px'
    }
  },
  data() {
    return {
      appName: this.$store.state.$config.appName,
      comDs: [],
      carouselMark: false
    }
  },
  created() {
    if (this.swiperSign) {
      this.getAdvise(this.swiperSign)
    }
  },
  methods: {
    getAdvise(sign) {
      home
        .getAppOperationGetAdvListBySign({
          sign,
          appName: this.appName
        })
        .then((res) => {
          if (res.success && res.data && res.data.length) {
            if (res.data[0].isRotation) {
              this.carouselMark = false
            } else {
              this.carouselMark = true
            }
            this.comDs = res.data
          }
        })
    },
    adverToPage(item) {
      adverTopage(item)
    },
    adverToPageXuan(val) {
      if (!val.advertisementIink && val.dateUrl) {
        const { href } = this.$router.resolve({
          path: val.dateUrl
        })
        window.open(href)
        // this.$router.push({ path: val.dateUrl })
      } else {
        this.adverToPage(val)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.swiper-box {

  .swiper-List {
    width: 100%;

    .lunbobox {
      height: 100%;
    }

    .myitemsCon {
      width: 100%;
      height: 100%;
      position: relative;
      cursor: pointer;

      .imagescon {
        width: 100%;
        height: 100%;
        object-fit: fill;
      }
    }

    ::v-deep .el-carousel__button {
      width: 10px;
      height: 10px;
      border-radius: 10px;
      opacity: 1;
    }

    ::v-deep .el-carousel__indicator.is-active button {
      background-color: $primaryColor;
    }
  }

  .swiper-vertical {
    .vertical {
      height: 120px;
      cursor: pointer;

      .verticalImg {
        width: 100%;
      }
    }
  }
}
</style>
