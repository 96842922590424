<template>
  <div class="headLine boxs" @click="myClick">
    <div class="heSign">
      <img src="../../assets/jixiPhoto/toutiao.png" alt srcset />
    </div>
    <div class="heTitleS oneline">{{headData[0].mainTitle}}</div>
    <div class="heScri">
      <div v-for="(i,j) in headData[0].contributionResourcesDTOS" :key="j">
        <div v-if="i.resourcesType === 'TEXT_IMAGE'" class="bottom">
          {{ i.content|filtersText }}
          <span style="color:red">[查看详情]</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import props from './props.js'
import { toDetails } from '@/utils/toDetail'
export default {
  props: {
    headData: {
      require: false,
      type: Array
    }
  },
  // name: 'headLine'
  filters: {
    // conChan(data) {
    //   if (data && data.length > 120) {
    //     data = data.slice(0, 120) + '...'
    //   }
    //   return data.replace(/<.*?>/g, '').replace(/&nbsp;/g, '')
    // }
    filtersText(val) {
      let txt = document.createElement('div')
      txt.innerHTML = val
      const content = txt.innerText || txt.textContent
      txt = null
      return content.length > 120 ? content.slice(0, 120) + '...' : content
    }
  },
  methods: {
    myClick() {
      toDetails(this.headData[0])
    }
  }
}
</script>

<style lang="scss" scoped>
.headLine {
  width: 957px;
  height: 96px;
  background: #ebf0f3;
  margin: 0px auto;
  position: relative;
  padding: 17px 0;
  box-sizing: border-box;
  cursor: pointer;
  .heSign {
    position: absolute;
    top: 0;
  }
  .heTitleS {
    font-size: 19px;
    font-family: Source Han Sans CN;
    font-weight: bold;
    color: #e60012;
    text-align: center;
    padding-left: 30px;
    padding-right: 30px;
  }
  .heScri {
    font-size: 13px;
    font-family: Source Han Sans CN;
    font-weight: 400;
    color: #969696;
    // text-align: center;
    // margin-top: 12px;
    // padding: 0 50px;
    box-sizing: border-box;
  }
  .bottom {
    width: 900px;
    height: 20px;
    line-height: 20px;
    padding: 5px 0 0 25px;
    color: #999999;
    word-break: break-all;
    word-wrap: break-word;
    font-size: 12px;
  }
}
</style>
