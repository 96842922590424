<template>
  <div class="multipleSlider">
    <!-- 轮播图 -->
    <div class="slideBox">
      <div class="elIconLeft icondata" @click="$refs.slider.$emit('slidePre')">
        <i class="el-icon-arrow-left" id="prev"></i>
      </div>
      <slider class="slider boxs" ref="slider" :options="options">
        <slideritem class="slideritemBox boxs" v-for="(item,index) in specialData" :key="index">
          <div class="slideImg" @click="imgLinkTo(item)">
            <!-- <img  v-if="item.topicCover&&JSON.parse(item.topicCover).length" :src="JSON.parse(item.cover)[0]|uploadFilters" alt=""> -->
            <img
              v-if="item.topicCover&&JSON.parse(item.topicCover).length"
              :src="JSON.parse(item.topicCover)[0] | uploadFilters"
              alt
            />
            <img v-else :src="require(`@/assets/defaultImg/${appName}.png`)" alt />
          </div>
        </slideritem>
      </slider>
      <div class="elIconRight icondata" @click="$refs.slider.$emit('slideNext')">
        <i class="el-icon-arrow-right" id="next"></i>
      </div>
    </div>
  </div>
</template>

<script>
import { slider, slideritem } from 'vue-concise-slider'
import props from './props.js'
import home from '@/api/index.js'
import { recommentJump } from '@/utils/toDetail'
export default {
  components: { slider, slideritem },
  mixins: [props],
  data() {
    return {
      options: {
        appName: this.$store.state.$config.appName,
        currentPage: 0,
        infinite: 4,
        slidesToScroll: 1,
        loop: true,
        pagination: false,
        speed: 300,
        loopedSlides: 4
        // autoplay: 3000,
        // freeze: false
      },
      specialData: []
    }
  },
  created() {
    this.focusOnImgList() // 专题数据
  },
  methods: {
    focusOnImgList() {
      home
        .getAppOperationSelectPage({
          appName: this.$store.state.$config.appName,
          page: 1,
          pageSize: 30
        })
        .then((res) => {
          if (res.success) {
            this.specialData = res.data.items
          }
        })
    },
    // 图片跳转
    imgLinkTo(val) {
      recommentJump(val, '/newslistWenMing')
      //     this.$router.push({
      //       path: '/newsList',
      //       query: {
      //         id: val.id,
      //         name: val.topicName
      //       }
      //     })
    }
  }
}
</script>

<style  lang="scss" scoped>
.multipleSlider {
  width: $primaryWidth;
  margin: 0px auto;
  .slideBox {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .slider {
      width: calc(100% - 72px);
      overflow: hidden;
      padding: 0px 12px;
    }
    .slideritemBox {
      width: 220px;
      height: 140px;
      margin-left: 12px;
      margin-right: 12px;
      .slideImg {
        width: 100%;
        height: 120px;
        border-radius: 6px;
        overflow: hidden;
        position: relative;
        cursor: pointer;
        transition: all 0.5s;
        &:hover {
          transform: scale(1.1, 1.1);
        }
        img {
          width: 100%;
          height: 100%;
        }
      }
    }
    .icondata {
      width: 24px;
      height: 78px;
      background: #f2f2f2;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
    }
  }
}
</style>
