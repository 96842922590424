<template>
  <div>
    <div class="footWebJX" v-if="appName==='jixirm'">
      <div v-html="webInfor&&webInfor.WEB_FOOTER_INFORMSTION"></div>
    </div>
    <div class="footWeb" :class="{'xuanzhou':appName === 'xuanzhourm','lixinrm':appName === 'lixinrm','qingyangrm':appName === 'qingyangrm'}" v-else>
      <div v-html="webInfor&&webInfor.WEB_FOOTER_INFORMSTION"></div>
    </div>
<!-- 复制后直接粘贴保存不要转回html在保存 -->
      <!-- <div style="width: 800px; margin: 20px auto;">
      <a
        href="http://www.12377.cn/"
        style="margin-top: 6px; display: block; float: left; margin-right: 10px"
        ><img
          src="https://apiadmin.yun.fst1994.net/api/media/convey/download/461580fc54f887a9005f9f4e2fddb5fd/simpleFormat?fileName=461580fc54f887a9005f9f4e2fddb5fd.png"
      /></a>
      <a
        href="http://bszs.conac.cn/sitename?method=show&amp;id=0922834C10191D41E053012819AC4180"
        style="display: block; float: left; margin-right: 10px"
        ><img
          src="https://apiadmin.yun.fst1994.net/api/media/convey/download/8cf00d38c12ed82679e7d9912f5aba24/simpleFormat?fileName=8cf00d38c12ed82679e7d9912f5aba24.png"
      /></a>
      <p style="text-align: center; color: #666">
        Copyright 郎溪新闻网 All Rights Reserved 版权所有
        本站资源未经网络信息中心许可不得转载 主办：中共郎溪县委宣传部
        承办：郎溪县融媒体中心 联系电话：0563-7021245
        <a
          href="http://first1994.mikecrm.com/T4kypGI"
          target="_blank"
          style="color: #666"
          >互联网违法信息举报</a
        >
        <a href="https://beian.miit.gov.cn" target="_blank" style="color: #666"
          >皖ICP备12001300号-2</a
        >
        管理维护：郎溪县融媒体中心
      </p>
    </div> -->
  </div>

  <!-- <div style="width: 1150px;margin: 10px auto 0 auto;height: 141px;display:flex;align-items:center">
      <a href="https://www.12377.cn/" style="display: block;float: left;margin-right:14px">
        <img src="https://first-common.bj.bcebos.com/common/jixisydw.png">
      </a>
      <img src="https://first-common.bj.bcebos.com/common/jixinu.png" style="float:left">
      <a href="https://www.12377.cn/" style="display: block;float: left;margin-left: 21px">
        <img src="https://first-common.bj.bcebos.com/common/jixijbzx.png">
      </a>
      <div style="width: 626px;font-size: 13px;margin-left:132px;line-height: 27px;">
        <p style="text-align: center;">主办：绩溪县融媒体中心 地址：绩溪县良安路曹家井39号</p>
        <p style="text-align: center;">
          <a target="_blank" style="color:#333;" href="https://beian.miit.gov.cn/">ICP备案： 皖ICP备16021462号-2</a> 涉网络暴力有害信息举报专区 “互联网算法推荐”专项举报 互联网违法和 不良信息暨涉未成年人举报电话：0563-8152905 举报邮箱：jixidst@163.com
        </p>
      </div>
  </div>-->
</template>

<script>
export default {
  created () {
  },
  computed: {
    webInfor() {
      return this.$store.state.$webInfor
    },
    appName() {
      return this.$store.state.$config.appName
    }
  }
}
</script>

<style  lang="scss" scoped>
.footWeb {
  width: 100%;
  min-width: $primaryWidth;
  overflow: hidden;
  border-top: 2px solid $primaryColor;
  padding: 15px 0px;
  display: flex;
  justify-content: center;
  margin: 0px auto;
}
.footWebJX {
  width: 100%;
  background-image: url("../../assets/jixiPhoto/deep.png");
  background-size: 100% 100%;
  min-width: $primaryWidth;
  // padding: 15px 0px;
  display: flex;
  justify-content: center;
  margin: 0px auto;
}
.xuanzhou{
  border-top: none;
  background-color: #9a9a9a;
}
.lixinrm{
  background-color: $primaryColor;
}
.qingyangrm{
  color: #FFFFFF;
  background: linear-gradient(0deg, #DD290B, #C0281C);
  ::v-deep a {
    color: #FFFFFF;
  }
}
</style>
