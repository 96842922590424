<!--  -->
<template>
  <div class="notice">
    <img src="../../assets/jixiPhoto/notice.png" alt srcset />
    <span class="noticeT">通知公告:</span>
    <el-carousel
      height="40px"
      style="width:477px;"
      direction="vertical"
      indicator-position="none"
      :interval="5000"
      v-if="newsDta"
    >
      <el-carousel-item
        v-for="(item,inde) in newsDta.datas"
        @click.native="todetailsList(item)"
        :key="inde"
      >
        <span class="oneline widthS">{{item.mainTitle}}</span>
        <span>{{item.releaseTime.slice(0,11)}}</span>
      </el-carousel-item>
    </el-carousel>
  </div>
</template>

<script>
import { toDetails } from '../../utils/toDetail'
export default {
  props: {
    newsDta: {
      require: false,
      type: Object,
      default: () => { }
    }
  },
  data() {
    return {

    }
  },
  // 生命周期 - 创建完成（访问当前this实例）
  created() {

  },
  // 生命周期 - 挂载完成（访问DOM元素）
  mounted() {

  },
  methods: {
    todetailsList(item) {
      // console.log('hhhhhhhhhh')
      toDetails(item)
    }
  }
}
</script>
<style scoped lang="scss">
/* @import url(); 引入css类 */
.notice {
  width: 100%;
  height: 40px;
  display: flex;
  align-items: center;
  margin-left: 2px;
  .noticeT {
    font-size: 13px;
    font-family: Source Han Sans CN;
    font-weight: bold;
    color: #d24444;
    margin: 0 5px;
  }
  .el-carousel__item {
    height: 100%;
    display: flex;
    align-items: center;
    font-size: 13px;
    font-family: Source Han Sans CN;
    font-weight: 400;
    justify-content: space-between;
    cursor: pointer;
    .widthS {
      width: 306px;
      display: block;
    }
    // color: #333333;
  }

  .el-carousel__item:nth-child(2n) {
    // background-color: #99a9bf;
  }

  .el-carousel__item:nth-child(2n + 1) {
    // background-color: #d3dce6;
  }
}
</style>
