<template>
  <div class="headWeb" :class="{ 'headwebCn': (naveType == 'indexBack') && backimgbox }">
    <!-- 这里是飘红头 -->
    <div class="backboxzhe" v-if="naveType == 'indexBack' && backimgbox && configWeb.appName !== 'qingyangrm'"
      @click="toOtherWeb"></div>
    <div class="backbox" v-if="naveType == 'indexBack' && backimgbox">
      <img :src="backimgbox.advertisementImage | uploadFilters" alt
        :style="{ height: configWeb.appName == 'qingyangrm' ? '610px' : '' }" />
    </div>
    <template v-if="configWeb.appName !== 'xuanzhourm' && configWeb.appName !== 'qingyangrm'">
      <!-- 这里页面顶部 郎溪的样式 -->
      <div v-if="naveType != 'noBanner' && bannerL.length" class="headBanner">
        <img class="leftLogo" src="../../assets/langxilogo.png" alt="">
        <!-- <div class="searchBoxData" v-if="search == 'navSearch'">
          <div style="border: 1px solid #DCDCDC;">
            <el-input size="smalltop" style="width:246px;height:38px" v-model="textTitle" placeholder="请输入关键字"
              @change="toSearch"></el-input>
          </div>
          <i class="el-icon-search icondata" @click="toSearch"></i>
        </div> -->
        <div class="imagess" v-if="bannerLTwo.length">
          <img class="rightLogo" v-for="(item, index) in bannerLTwo" :key="index" :class="{ chosedimgs: index == chosedIndex }"
            :src="item.advertisementImage | uploadFilters" alt @click="bannerToOther(item)" />
        </div>
        <div class="imagess">
          <img class="rightLogo" v-for="(item, index) in bannerL" :key="index" :class="{ chosedimgs: index == chosedIndex }"
            :src="item.advertisementImage | uploadFilters" alt @click="bannerToOther(item)" />
        </div>
      </div>

      <!-- 头部logo 绩溪样式-->
      <div class="logoDiv" v-if="logoData.length">
        <div class="logoPart" :class="{ 'lixinrm': configWeb.appName == 'lixinrm' }">
          <img class="logo" v-if="logoData[0]" :src="logoData[0].advertisementImage | uploadFilters" alt />
          <img class="gif" v-if="logoData[1]" :src="logoData[1].advertisementImage | uploadFilters" alt />
        </div>
      </div>
      <!-- 这里是导航页面 -->
      <div class="navaBox"
        :class="{ 'havebackNav': (naveType == 'indexBack') && backimgbox, 'lixinrm': naveType == 'indexBack' && backimgbox && configWeb.appName === 'lixinrm' }"
        v-if="showNavigation">
        <div class="navContent"
          :class="{ 'searbox': search == 'navSearch' && !(configWeb.appName === 'langxirm' && bannerL.length) }">
          <div class="itemCon"
            :class="{ itemConAct: item.dataLink == $route.fullPath, 'lixin': configWeb.appName === 'lixinrm' }"
            v-for="(item, index) in myallTitles[0].info" :key="index" @click="toPage(item)">
            <span>{{ item.navigationName }}</span>
          </div>

          <div class="searchBox" v-if="search == 'navSearch' && !(configWeb.appName === 'langxirm' && bannerL.length)">
            <el-input size="small" style="width:120px" v-model="textTitle" placeholder="请输入关键字"
              @change="toSearch"></el-input>
            <i class="el-icon-search icondata" @click="toSearch"></i>

            <el-button class="loginBtn" type="primary" v-if="configWeb.haveLogin" size="mini" @click="loginBtnClick">{{
    loginStatus ? "退出登录" : "登录" }}</el-button>
          </div>
        </div>
      </div>
    </template>
    <!-- 宣州 -->
    <template v-if="configWeb.appName === 'xuanzhourm'">
      <div class="navaBox navXuan" :class="{ 'havebackNav': naveType == 'indexBack' && backimgbox }" v-if="showNavigation">
        <div class="navContent">
          <div class="leftBox">
            <div class="backBox" @click="goBackIndex">
              <img src="@/assets/xuanzhou/indexBack.png" alt="">
              <span>首页</span>
            </div>
            <div class="list-nav">
              <div class="itemConXuan" :class="{ itemConAct: item.dataLink == $route.fullPath }"
                v-for="(item, index) in myallTitles[0].info" :key="index" @click="toPage(item)">
                <span>{{ item.navigationName }}</span>
              </div>
            </div>
          </div>

          <div class="searchBoxXuan" v-if="search == 'navSearch'">
            <div class="searchRight">
              <el-input size="small" style="width:215px;height:25px" v-model="textTitle" placeholder="请输入关键字"
                @change="toSearch"></el-input>
              <img class="searchIcon" src="@/assets/xuanzhou/sousuo.png" alt="">
            </div>
            <div class="time-tianqi">
              <span>{{ currentTime }}</span>
              <p class="weath" v-if="weatherInfo" @click="goToWeather">
                <span>{{ weatherInfo.city }}</span>
                <img :src="weatherImage" alt="">
                <span>{{ weatherInfo.temperature }}℃</span>
              </p>
            </div>
          </div>
        </div>
      </div>
    </template>
    <template v-if="configWeb.appName === 'qingyangrm'">
      <changeSeeding swiperSign="WZTBGG" height="265px" v-if="this.$route.fullPath == '/'"></changeSeeding>
      <div class="headTopBanner"
        :style="{ backgroundImage: bannerL.length && !backimgbox ? 'url(' + backImgFilter(bannerL[0].advertisementImage) + ')' : null }">
        <img class="toptLogo" :src="this.$store.state.$webInfor.WEB_LOGO | uploadFilters" alt=""
          v-if="this.$store.state.$webInfor" @click="navToHome">
        <div class="searchBoxDataBottom" v-if="search == 'navSearch'">
          <div style="border: 1px solid #DCDCDC;">
            <el-input size="smalltop" style="width:610px;height:42px" v-model="textTitle" placeholder="请输入关键字"
              @change="toSearch"></el-input>
          </div>
          <i class="el-icon-search qingyangIcondata" @click="toSearch"></i>
        </div>
      </div>
      <div class="navaBox" style="height: 56px;" :class="{ 'qingyanghavebackNav': (naveType == 'indexBack') && backimgbox }">
        <div class="navContent">
          <div class="itemCon" v-for="(item, index) in myallTitles[0].info" :key="index" @click="toPage(item)">
            <span>{{ item.navigationName }}</span>
          </div>
        </div>
      </div>
    </template>
    <loginBox :loginVisible="loginShowVisible" @changeLoginVisible="changeLoginVisibleStatus"></loginBox>
  </div>
</template>

<script>
import home from '@/api/index.js'
import props from './props.js'
import changeSeeding from '@/components/changeSeeding/changeSeeding.vue'
import { adverTopage } from '@/utils/toDetail'
import loginBox from '../loginIn/loginIn'
import axios from 'axios'
export default {
  mixins: [props],
  components: {
    loginBox,
    changeSeeding
  },
  data() {
    return {
      bannerLTwo: [],
      chosedIndex: 0,
      loginShowVisible: false, // 登录注册
      textTitle: '',
      backimgbox: '',
      bannerL: [],
      myallTitles: [{ info: [] }],
      logoData: [],
      weatherImage: require('@/assets/tianqi/sun.png'),
      weatherInfo: null,
      currentTime: ''
    }
  },
  computed: {
    configWeb() {
      return this.$store.state.$config
    },
    loginStatus() {
      const staus = this.$store.state.token && this.$store.state.userInfo
      return staus
    }

  },
  created() {
    this.mynav() // 这里是我的导航
    if (this.naveType === 'indexBack') {
      if (this.adver) {
        // console.log('this.naveType', this.naveType)
        if (this.redsign) {
          this.adverDataLink(this.redsign, 'pc')
        }
      } else {
        this.bigBckg() // 网站飘红
      }
    }
    if (this.naveType !== 'noBanner') {
      if (this.adver) {
        // console.log(this.bannersign, '6')
        if (this.bannersign) {
          this.adverDataLink(this.bannersign, 'banner')// 广告查图片
        }
      } else {
        this.bannerData() // 这里是我的banner头页面
      }
    }
    if (this.configWeb.appName === 'xuanzhourm') {
      this.getWeather()
      this.getTime()
    }
    if (this.$route.query.keyWord) {
      this.textTitle = this.$route.query.keyWord
    }
  },
  methods: {
    navToHome() {
      // this.$router.push({ path: '/' })
      const { href } = this.$router.resolve({
        path: '/'
      })
      window.open(href)
    },
    backImgFilter(url) {
      if (url) {
        if (
          url.indexOf(this.$store.state.$config.baseUrl) === -1 &&
          url.indexOf('http://') === -1 &&
          url.indexOf('https://') === -1
        ) {
          return `${this.$store.state.$config.baseUrl}media${url}`
        } else {
          return url
        }
      }
    },
    adverDataLink(sign, name) {
      // console.log(sign)
      home
        .getAppOperationGetAdvListBySign({
          sign: sign,
          appName: this.configWeb.appName
        })
        .then((res) => {
          if (res.success && res.data && res.data.length) {
            if (name === 'pc') {
              this.$emit('indexWidth')
              this.backimgbox = res.data[0] // 飘红头
            } else {
              this.logoData = res.data
            }
          }
        })
    },
    // 点击登录按钮的点击事件
    loginBtnClick() {
      if (this.loginStatus) { // 如果有登陆信息则显示退出登录的按钮
        this.$confirm('确认退出登录吗').then(async () => {
          this.$store.dispatch('loginOut')
        })
      } else { // 显示登录按钮
        this.loginShowVisible = true
      }
    },
    changeLoginVisibleStatus() {
      this.loginShowVisible = false
    },
    toSearch() { // 跳转搜索页面
      if (this.textTitle) {
        if (this.configWeb.appName === 'jixirm') {
          this.$router.push({
            path: '/searchNewslist',
            query: {
              keyWord: this.textTitle
            }
          })
        } else {
          this.$router.push({
            path: '/newslist',
            query: {
              keyWord: this.textTitle
            }
          })
        }
      } else {
        this.$message({
          showClose: true,
          message: '请输入关键字',
          type: 'error'
        })
      }
    },
    toOtherWeb() {
      adverTopage(this.backimgbox)
    },
    bannerToOther(item) {
      adverTopage(item)
    },
    toPage(item) {
      if (item.otherLink) {
        window.open(item.otherLink)
      } else {
        let colum = ''
        if (item.columnSign) {
          if (item.dataLink.indexOf('?') === -1) {
            colum = '?column=' + item.columnSign
          } else {
            colum = '&column=' + item.columnSign
          }
        }
        const isHasSameRou = (this.$route.fullPath.indexOf(item.dataLink) !== -1)// 是不是具有相同的路径
        const isHasSameParams = (this.$route.fullPath.indexOf(colum) !== -1)// 是不是具有相同的参数
        if (colum.length <= 0) {
          if (item.dataLink !== this.$route.fullPath) {
            const { href } = this.$router.resolve({
              path: item.dataLink + colum
            })
            window.open(href)
          }
        } else {
          if (!isHasSameRou || !isHasSameParams) {
            const { href } = this.$router.resolve({
              path: item.dataLink + colum
            })
            window.open(href)
          }
        }
      }
    },
    mynav() {
      // console.log('www')
      // 导航
      home
        .getAppOperationGetNavListBySign({
          sign: this.sign,
          appName: this.configWeb.appName
        })
        .then((res) => {
          // console.log('res', res)
          if (res.success) {
            // console.log('daohangdaohang', res)
            this.myallTitles = res.data
          }
        })
    },
    // 大的飘红接口
    bigBckg() {
      if (this.redsign) {
        home
          .getAppOperationGetAdvListBySign({
            sign: this.redsign,
            appName: this.configWeb.appName
          })
          .then((res) => {
            // console.log('res', res)
            if (res.success && res.data && res.data.length) {
              this.$emit('indexWidth')
              this.backimgbox = res.data[0] // 飘红头
            }
          })
      }
    },
    // 头banner 图
    bannerData() {
      home
        .getAppOperationGetAdvListBySign({
          sign: this.bannersign,
          appName: this.configWeb.appName
        })
        .then((res) => {
          // console.log('我的banner头', res)
          if (res.success && res.data && res.data.length) {
            this.bannerL = res.data // banner广告
            setInterval(() => {
              if (this.chosedIndex === this.bannerL.length - 1) {
                this.chosedIndex = 0
              } else {
                this.chosedIndex++
              }
            }, 3000)
          }
        })
      console.log(this.bannersignTwo, '===jinll吗')
      if (this.bannersignTwo) {
        home
          .getAppOperationGetAdvListBySign({
            sign: this.bannersignTwo,
            appName: this.configWeb.appName
          })
          .then((res) => {
            if (res.success && res.data && res.data.length) {
              this.bannerLTwo = res.data // banner广告
            }
          })
      }
    },
    // 回首页
    goBackIndex() {
      this.$router.push({ path: '/' })
    },
    // 获取天气
    getWeather() {
      axios.get('https://restapi.amap.com/v3/ip', {
        params: {
          key: this.$store.state.$config.headWeb.weatherKey
        }
      }).then(ret => {
        console.log(ret.data, '46556464')
        if (ret.data.status === '1') {
          axios.get('https://restapi.amap.com/v3/weather/weatherInfo', {
            params: {
              city: ret.data.adcode,
              key: this.$store.state.$config.headWeb.weatherKey
            }
          }).then(res => {
            // console.log(res.data)
            if (res.data.status === '1') {
              this.weatherInfo = res.data.lives[0]
              if (res.data.lives[0].weather === '晴') {
                this.weatherImage = require('@/assets/tianqi/sun.png')
              } else if (res.data.lives[0].weather === '少云' || res.data.lives[0].weather === '晴间多云' || res.data.lives[0].weather === '多云') {
                this.weatherImage = require('@/assets/tianqi/duoyun.png')
              } else if (res.data.lives[0].weather === '暴雪') {
                this.weatherImage = require('@/assets/tianqi/baoxue.png')
              } else if (res.data.lives[0].weather === '暴雨' || res.data.lives[0].weather === '大暴雨' || res.data.lives[0].weather === '特大暴雨' || res.data.lives[0].weather === '强阵雨' || res.data.lives[0].weather === '暴雨-大暴雨' || res.data.lives[0].weather === '大暴雨-特大暴雨' || res.data.lives[0].weather === '极端降雨') {
                this.weatherImage = require('@/assets/tianqi/baoyu.png')
              } else if (res.data.lives[0].weather === '大雪' || res.data.lives[0].weather === '大雪-暴雪' || res.data.lives[0].weather === '阵雪') {
                this.weatherImage = require('@/assets/tianqi/daxue.png')
              } else if (res.data.lives[0].weather === '大雨' || res.data.lives[0].weather === '大雨-暴雨') {
                this.weatherImage = require('@/assets/tianqi/dayu.png')
              } else if (res.data.lives[0].weather === '阵雨' || res.data.lives[0].weather === '雷阵雨' || res.data.lives[0].weather === '雷阵雨并伴有冰雹' || res.data.lives[0].weather === '强雷阵雨') {
                this.weatherImage = require('@/assets/tianqi/leizhengyu.png')
              } else if (res.data.lives[0].weather === '霾' || res.data.lives[0].weather === '中度霾' || res.data.lives[0].weather === '重度霾' || res.data.lives[0].weather === '严重霾' || res.data.lives[0].weather === '雾' || res.data.lives[0].weather === '浓雾' || res.data.lives[0].weather === '强浓雾' || res.data.lives[0].weather === '轻雾' || res.data.lives[0].weather === '大雾' || res.data.lives[0].weather === '特强浓雾') {
                this.weatherImage = require('@/assets/tianqi/wumai.png')
              } else if (res.data.lives[0].weather === '小雪' || res.data.lives[0].weather === '雪' || res.data.lives[0].weather === '小雪-中雪') {
                this.weatherImage = require('@/assets/tianqi/xiaoxue.png')
              } else if (res.data.lives[0].weather === '雨' || res.data.lives[0].weather === '小雨' || res.data.lives[0].weather === '小雨-中雨' || res.data.lives[0].weather === '冻雨' || res.data.lives[0].weather === '毛毛雨/细雨') {
                this.weatherImage = require('@/assets/tianqi/xiaoyu.png')
              } else if (res.data.lives[0].weather === '阴') {
                this.weatherImage = require('@/assets/tianqi/ying.png')
              } else if (res.data.lives[0].weather === '雨夹雪' || res.data.lives[0].weather === '雨雪天气' || res.data.lives[0].weather === '阵雨夹雪') {
                this.weatherImage = require('@/assets/tianqi/yujiaxue.png')
              } else if (res.data.lives[0].weather === '中雪' || res.data.lives[0].weather === '中雪-大雪') {
                this.weatherImage = require('@/assets/tianqi/zhongxue.png')
              } else if (res.data.lives[0].weather === '中雨' || res.data.lives[0].weather === '中雨-大雨') {
                this.weatherImage = require('@/assets/tianqi/zhongyu.png')
              } else if (res.data.lives[0].weather === '有风' || res.data.lives[0].weather === '微风' || res.data.lives[0].weather === '平静' || res.data.lives[0].weather === '和风' || res.data.lives[0].weather === '清风' || res.data.lives[0].weather === '强风/劲风' || res.data.lives[0].weather === '疾风' || res.data.lives[0].weather === '大风' || res.data.lives[0].weather === '烈风' || res.data.lives[0].weather === '风暴' || res.data.lives[0].weather === '狂爆风' || res.data.lives[0].weather === '飓风' || res.data.lives[0].weather === '热带风暴' || res.data.lives[0].weather === '龙卷风' || res.data.lives[0].weather === '浮尘' || res.data.lives[0].weather === '扬沙' || res.data.lives[0].weather === '沙尘暴' || res.data.lives[0].weather === '强沙尘暴') {
                this.weatherImage = require('@/assets/tianqi/feng.png')
              } else {
                this.weatherImage = require('@/assets/tianqi/weizhi.png')
              }
              // console.log('hhhhhhhhhhhh')
            }
          })
        }
      })
    },
    // 时间
    getTime() {
      const date = new Date()
      const year = date.getFullYear()
      let month = date.getMonth() + 1
      let day = date.getDate()
      if (month < 10) month = `0${month}`
      if (day < 10) day = `0${day}`
      this.currentTime = year + '年' + month + '月' + day + '日'
    },
    goToWeather() {
      window.open('http://tianqi.2345.com/shushan/71872.htm')
    }
  }
}
</script>

<style lang="scss" scoped>
.headWeb {
  position: relative;
  min-width: $primaryWidth;

  .backboxzhe {
    width: 100%;
    height: 160px;
    background-color: transparent;
    cursor: pointer;
  }

  .backbox {
    width: 100%;
    position: absolute;
    top: 0px;
    z-index: -1;

    img {
      width: 100%;
    }
  }

  .headBanner {
    display: flex;
    width: 1200px;
    height: 149px;
    margin: 0px auto;
    align-items: center;
    justify-content: space-between;

    .leftLogo {
      width: 250px;
      height: 83px;
    }

    .searchBoxData {
      width: 320px;
      height: 40px;
      display: flex;
      align-items: center;
      position: relative;

      .smalltop {
        height: 38px;
      }

      .icondata {
        font-size: 20px;
        color: #fff;
        position: absolute;
        right: 0px;
        width: 76px;
        height: 40px;
        background: $primaryColor;
        display: flex;
        justify-content: center;
        align-items: center;
      }

      ::v-deep .el-input__inner {
        border: none;
        height: 38px;
      }
    }

    .imagess {
      width: 413px;
      height: 90px;
      position: relative;

      .rightLogo {
        transition: all 0.5s;
        position: absolute;
        width: 413px;
        height: auto;
        z-index: -1;
        opacity: 0;
        cursor: pointer;
      }

      .chosedimgs {
        z-index: 10;
        opacity: 1;
        object-fit: contain;
      }
    }

  }

  .logoDiv {
    width: 100%;
    // margin: 0px auto;
    display: flex;

    // justify-content: space-between;
    align-items: center;
    height: 120px;
    // padding: 0 20%;
    box-sizing: border-box;
    position: relative;
    z-index: -1;

    // margin-bottom: 20px;
    .logoPart {
      width: $primaryWidth;
      padding: 0 25px;
      margin: 0 auto;
      box-sizing: border-box;
      background-color: #fff;

      .logo {
        width: 400px;
      }

      .gif {
        width: 648px;
        // margin-top: 20px;
        margin-left: 96px;
      }
    }

    .lixinrm {
      overflow: hidden;
      display: flex;
      justify-content: space-between;
      align-items: center;
      height: 100%;

      .logo {
        width: 218px;
        height: auto;
        // flex: 1;
      }

      .gif {
        width: 940px;
        height: auto;
        margin-left: 0px;
      }
    }
  }

  .haveback {
    width: calc($primaryWidth + 20px);
    margin: 0px auto;
  }

  .navaBox {
    width: 100%;
    height: 50px;
    background-color: $primaryColor;
  }

  .havebackNav {
    width: calc($primaryWidth + 20px);
    margin: 0px auto;
  }

  .lixinrm {
    width: $primaryWidth;
    border-left: 1px solid #fff;
    box-sizing: border-box;
  }

  .navContent {
    width: $primaryWidth;
    height: 100%;
    display: flex;
    justify-content: space-around;
    position: relative;
    margin: 0px auto;

    .itemCon {
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 0px 20px;
      font-size: 18px;
      color: #fff;
      cursor: pointer;
    }

    .lixin:hover {
      background-color: #00A0E9;
    }

    .searchBox {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      position: absolute;
      right: 20px;
      height: 100%;

      .icondata {
        font-size: 18px;
        margin-left: 8px;
        font-weight: bold;
        color: #fff;
        cursor: pointer;
      }

      .loginBtn {
        margin-left: 10px;
        font-size: 16px;
        text-align: center;
      }

      .el-button--primary {
        background: transparent !important;
        border: unset;
      }
    }

    // .itemConAct{
    //   color: $primaryColor;
    //   background-color: #fff;
    // }
    ::v-deep .el-input__inner {
      border: none;
    }
  }

  .searbox {
    justify-content: flex-start;
    padding-right: 244px;
    box-sizing: border-box;
  }
}

.headwebCn {
  min-width: calc($primaryWidth + 20px);
}

// 宣州
.navXuan {
  height: 75px !important;

  .navContent {
    width: $primaryWidth;
    height: 100%;
    display: flex;
    justify-content: space-between;
    position: relative;
    align-items: center;
    margin: 0px auto;

    .leftBox {
      display: flex;
      padding-left: 8px;
    }

    .backBox {
      display: flex;
      flex-direction: column;
      width: 140px;
      font-size: 14px;
      color: #fff;
      align-items: center;
      cursor: pointer;

      img {
        width: 30px;
        height: 29px;
        margin-bottom: 5px;
      }
    }

    .list-nav {
      display: flex;
      flex-wrap: wrap;
      width: 636px;
    }

    .itemConXuan {
      width: 106px;
      height: 30px;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 16px;
      color: #fff;
      cursor: pointer;
    }

    .searchBoxXuan {
      display: flex;
      justify-content: center;
      flex-direction: column;
      height: 100%;
      padding-top: 10px;

      .searchIcon {
        width: 37px;
        height: 26px;
        margin-left: 10px;
      }

      ::v-deep .el-input__inner {
        height: 25px;
        line-height: 25px;
        border-radius: 10px;
      }

      .searchRight {
        display: flex;
        align-items: center;
      }

      .time-tianqi {
        font-size: 12px;
        color: #fff;
        // margin-top: 8px;
        display: flex;
        justify-content: space-between;
        align-items: center;

        .weath {
          display: flex;
          align-items: center;
          cursor: pointer;

          img {
            width: 32px;
            height: 32px;
          }
        }
      }
    }

    ::v-deep .el-input__inner {
      border: none;
    }
  }
}

.headTopBanner {
  display: flex;
  height: 418px;
  margin: 0px auto;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;

  .toptLogo {
    width: 662px;
    height: 123px;
    margin-bottom: 50px;
    cursor: pointer;
  }

  .searchBoxDataBottom {
    width: 610px;
    height: 40px;
    display: flex;
    align-items: center;
    position: relative;

    .smalltop {
      height: 38px;
    }

    .icondata {
      font-size: 20px;
      color: #fff;
      position: absolute;
      right: 0px;
      width: 76px;
      height: 42px;
      background: $primaryColor;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    ::v-deep .el-input__inner {
      border: none;
      height: 42px;
    }
  }
}

.qingyangIcondata {
  font-size: 20px;
  color: #fff;
  position: absolute;
  right: -2px;
  width: 76px;
  height: 44px;
  background: $primaryColor;
  display: flex;
  justify-content: center;
  align-items: center;
}

.qingyanghavebackNav {
  width: calc($primaryWidth + 60px) !important;
  margin: 0px auto !important;
}
</style>
