// 推荐轮播图
export default {
  props: {
    items: {
      require: false,
      type: Object
    },
    fontSize: {
      require: false,
      type: String,
      default: '16px'
    },
    type: {
      require: false,
      type: String,
      default: ''
    }
  }
}
