<template>
  <div class="bigSideBox">
    <div class="bigHead boxs">
      <div class="myboxdata">
        <div
          class="titles"
          @mouseenter="mouChange(index)"
          @click="clicChange(index)"
          :class="{tiActive:choosedIndex==index,smallline:lineType=='smallline'||lineType=='smallnoline'}"
          v-for="(item,index) in mytitles"
          :key="index"
        >
          {{item.navigationName}}
          <div class="icons" v-show="!(lineType=='smallline')&&choosedIndex==index"></div>
        </div>
      </div>
    </div>
    <!-- 标题底下的内容啊 -->
    <div class="hohecon">
      <div v-for="(item,index) in mytitles" :key="index">
        <div v-show="choosedIndex==index">
          <slot :name="item.id"></slot>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import props from './props.js'
export default {
  mixins: [props],
  data() {
    return {
      choosedIndex: 0
    }
  },
  methods: {
    mouChange(index) {
      if (this.changeType === 'hover') {
        this.choosedIndex = index
      }
    },
    clicChange(index) {
      if (this.changeType === 'click') {
        this.choosedIndex = index
      }
      const item = this.mytitles[this.choosedIndex]
      if (item.otherLink) {
        window.open(item.otherLink)
      } else if (item.dataLink) {
        let colum = ''
        if (item.dataLink.indexOf('?') === -1) {
          colum = '?column=' + item.columnSign
        } else {
          colum = '&column=' + item.columnSign
        }
        if (this.$route.fullPath !== item.dataLink) {
          const { href } = this.$router.resolve({
            path: item.dataLink + colum
          })
          window.open(href)
        }
      }
    },
    // 跳转到其他页面
    toOtherData() {
      const item = this.mytitles[this.choosedIndex]
      if (item.otherLink) {
        window.open(item.otherLink)
      } else if (item.dataLink) {
        let colum = ''
        if (item.dataLink.indexOf('?') === -1) {
          colum = '?column=' + item.columnSign
        } else {
          colum = '&column=' + item.columnSign
        }
        if (this.$route.fullPath !== item.dataLink) {
          const { href } = this.$router.resolve({
            path: item.dataLink + colum
          })
          window.open(href)
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.bigSideBox {
  width: 100%;
  .hohecon {
    width: 100%;
    margin-top: 14px;
  }
}
.bigHead {
  width: 100%;
  height: 45px;
  border: 1px solid #d2d2d2;

  align-items: center;
  .myboxdata {
    // width: 412px;
    display: flex;
    // justify-content: space-between;
  }
  .titles {
    width: 138px;
    text-align: center;

    height: 45px;
    line-height: 45px;
    font-size: 17px;
    position: relative;
    // padding: 0 20px;
    cursor: pointer;
    border-left: 1px solid #d2d2d2;
    .icons {
      position: absolute;
      bottom: -5px;
      left: 50%;
      transform: translateX(-50%);
      // border-left: 8px solid transparent;

      // border-top: 5px solid $primaryColor;
    }
  }
  .tiActive {
    // background: $primaryColor;
    background-size: 100% 43.4px;
    background-repeat: no-repeat;
    background-image: url("../../assets/jixiPhoto/changeIcon.png");
    color: $primaryColor;
  }
  .smallline {
    background: #fff;
    color: #333;
    padding: 0 0px;
    margin-left: 20px;
    margin-right: 20px;
    &:first-child {
      margin-left: 0px;
    }
  }
  .tiActive.smallline {
    font-weight: bold;
    color: $primaryColor;
    border-bottom: 2px solid $primaryColor;
  }
  .morecon {
    font-size: 14px;
    color: #858585;
    cursor: pointer;
  }
}
.bigHeadline {
  border-bottom: 1px solid #bfbfbf;
}
.nolineHead {
  border-bottom: none;
}
</style>
