<template>
  <div style="width: 100%; height: 100%;">
    <slider ref="slider" :options="optionsTwo" @init="init">
      <template>
        <slideritem
          v-for="(item, index) in swiperList"
          :key="index"
          :index="index"
          :pageLength="swiperList.length"
        >
          <div class="imgDiv">
            <img :src="item.advertisementImage | uploadFilters" alt @click="imgLinkTo(item)" />
            <p>{{ item.mainTitle }}</p>
          </div>
        </slideritem>
      </template>
    </slider>
  </div>
</template>
<script>
import { slider, slideritem } from 'vue-concise-slider'
import { adverTopage } from '@/utils/toDetail'
export default {
  props: {
    swiperList: {
      type: Array
    }
  },
  data() {
    return {
      timer: null,
      optionsTwo: {
        autoplay: 3000,
        slidesPerview: 2,
        loop: true
        // autoplay: 2000,
      }
    }
  },
  components: {
    slider,
    slideritem
  },
  mounted() { },
  beforeDestroy() {
    clearInterval(this.timer)
    this.timer = null
  },
  methods: {
    init() {
      // console.log("init", data);
    },
    // 广告跳转
    imgLinkTo(val) {
      adverTopage(val)
    },
    // 滚动到上一页
    slide() {
      this.$refs.slider.$emit('slidePre')
    },
    slideNext() {
      this.$refs.slider.$emit('slideNext')
    }
  }
}
</script>
<style lang="scss" scoped>
.imgDiv {
  width: 100%;
  height: 100%;
  position: absolute;
  cursor: pointer;
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  p {
    display: block;
    position: absolute;
    bottom: 0px;
    left: 0px;
    line-height: 40px;
    z-index: 9;
    background-image: linear-gradient(
      to top,
      rgba(0, 0, 0, 0.3),
      rgba(0, 0, 0, 0)
    );
    width: 100%;
    color: #fff;
    font-size: 14px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}

.left {
  left: 0;
}
.right {
  right: 0;
}
.buttonsStep {
  width: 24px;
  height: 45px;
  text-align: center;
  position: absolute;
  top: 50%;
  z-index: 999;
  background: rgba(255, 255, 255, 0.5);
  text-align: center;
  margin-top: -22.5px;
  cursor: pointer;
  img {
    margin-bottom: 6px;
  }
}
::v-deep .slider-pagination-bullets {
  // background-color: aqua;
  position: absolute;
  left: 45%;
  // background-color: #fff;
}
::v-deep .slider-pagination-bullet {
  background: #fff;
  opacity: 1;
}
::v-deep .slider-pagination-bullet-active {
  background: #f3303e;
}
</style>
